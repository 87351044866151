const organization = {
  organizations: 'Comptes',
  search_organization: 'Rechercher des comptes',
  create_organization: 'Créer des comptes',
  add_organization: 'Ajouter un compte',
  add_new_organization: 'Ajouter un nouveau compte',
  organization_role: 'Rôle de compte',
  controllers: 'Contrôleurs',
  search_controller: 'Contrôleurs de recherche',
  organization: 'Compte',
  monthly: 'Mensuel',
  yearly: 'Annuel',
  doors: 'Des portes',
  doors_licenses: 'Permis de porte',
  pricing: 'Tarification',
  profile: 'Profil',
  billing: 'Facturation',
  search_billing: 'Rechercher Facturation',
  licenses: 'Licences',
  subscription: 'Abonnement',
  users: 'Utilisateurs',
  fee: 'Frais',
  amount: 'montant',
  how_many_doors_msg: 'Combien de portes voulez-vous activer?',
  payment_methods: 'Méthodes de payement',
  name_on_card: 'Nom sur la carte',
  card_number: 'Numéro de carte',
  expiration_date: 'Date d\'expiration',
  is_default: 'Faire par défaut',
  expires_at: 'La carte expire à',
  confirm_checkout_msg: 'Êtes-vous sûr de vouloir payer?',
  skip_checkout_msg:
    'Vous pourrez acheter une porte plus tard sur votre compte. Voulez-vous continuer ?',
  request_processed: 'Votre demande a été traitée',
  set_organization: 'Définir le compte',
  change_organization_msg: 'Voulez-vous changer de compte?',
  select_organization_msg: 'Veuillez accéder aux comptes et en sélectionner un',
  summary: 'Résumé',
  invoices: 'Factures',
  invoice: 'Facture',
  paid: 'Payé',
  pending: 'En Attente',
  mobile_passes: 'Pass Mobiles',
  next_invoice: 'Prochaine Facture',
  last_update: 'Dernière mise à jour',
  organization_created_msg: '{{account}} a été créé!',
  set_default: 'Définir par defaut',
  activate_controller_msg:
    'Il n\'y a pas encore de contrôleurs ajoutés. Démarrez votre accès en ajoutant votre premier contrôleur',
  single_site: 'Site unique',
  validate: {
    type_email: 'S\'il vous plaît, tapez votre e-mail',
    verification_code: 'Le code de vérification',
    validation_label:
      'A validation code has been sent to your email, please check your inbox',
    done: 'Fait',
    success: 'Succès ! Vous pouvez maintenant utiliser votre compte CDVI Cloud',
    continue: 'Continuer',
    finish: 'Finir'
  },
  table: {
    company: 'ENTREPRISE',
    full_name: 'NOM ET PRÉNOM',
    email: 'E-MAIL',
    country: 'PAYS',
    last_login: 'DERNIÈRE CONNEXION',
    active: 'ACTIF',
    address: 'ADRESSE',
    status: 'STATUT',
    site: 'PLACER',
    serial_number: 'NUMÉRO DE SÉRIE',
    ip_address: 'ADRESSE IP',
    port: 'PORT',
    organization: 'COMPTE',
    firmware_version: 'VERSION DU FIRMWARE',
    date: 'DATE',
    invoice: 'FACTURE',
    amount: 'MONTANT',
    payment: 'PAIEMENT',
    invoice_number: 'NUMÉRO FACTURE',
    type: 'TAPER',
    total: 'TOTAL'
  },
  form: {
    type: 'Type de concessionnaire',
    company: 'Entreprise',
    full_name: 'Nom et prénom',
    first_name: 'Prénom',
    main_contact_name: 'Contact principal - Prénom',
    last_name: 'Nom de famille',
    second_contact_name: 'Deuxième contact - Prénom',
    second_contact_last_name: 'Nom de famille',
    email: 'E-mail',
    country: 'Pays',
    address: 'Adresse',
    city: 'Ville',
    state: 'État',
    zip_code: 'Code postal',
    phone: 'Téléphone',
    mobile: 'Mobile',
    create_admin: 'Créer un utilisateur',
    timezone: 'Fuseau horaire',
    username: 'Nom d\'utilisateur'
  },
  information: {
    organization: 'Compte',
    dealer: 'Marchand',
    company: 'Entreprise',
    first_name: 'Prénom',
    last_name: 'Nom de famille',
    email: 'E-mail',
    username: 'Nom d\'utilisateur',
    website: 'Site Internet',
    country: 'Pays',
    address: 'Adresse',
    city: 'Ville',
    state: 'État',
    zipcode: 'Code postal',
    timezone: 'Fuseau horaire',
    region: 'Région',
    phone: 'Téléphone'
  },
  modal: {
    title: 'Abonnement à la mise à niveau d\'impression',
    remove_title: 'Supprimer  Subscription',
    description: 'Combien de portes souhaitez-vous ajouter?',
    current_subscription: 'Abonnement Actuel',
    previous: 'Précédent',
    checkout: 'Vérifier',
    change_card: 'Changer de Carte',
    invoice_details: 'Détails de la facture',
    current: 'Actuel',
    next_cycle: 'Cycle Suivant',
    request_processed: 'Votre demande a été traitée',
    print_receipt: 'Imprimer le reçu',
    download_receipt: 'Télécharger le reçu',
    finish: 'Finir',
    due_today: 'Dû Aujourd\'hui',
    confirmation_description:
      'Le paiement ne peut pas être modifié après avoir vérifié cette fenêtre, Etes-vous sûr de vouloir procéder au paiement?',
    total_amount: 'Montant total',
    remove_confirmation_description:
      'Lorsque vous cliquez sur le bouton \'confirmer\', le processus d\'élimination des portes ou des laissez-passer mobiles se fera immédiatement.',
    remove_description: 'Combien de portes souhaitez-vous supprimer?',
    remove: 'Retirer',
    confirmation: 'Confirmation',
    delete_payment_description: 'Voulez-vous vraiment supprimer cette carte',
    default_payment_description:
      'Voulez-vous vraiment définir cette carte par défaut'
  },
  error: {
    invalid_email: 'Format d\'email invalide'
  },
  set_multisite_msg:
    'Êtes-vous sûr de vouloir modifier <b>{{name}}</b> de Site unique à Sites multiples',
  set_singlesite_msg:
    'Êtes-vous sûr de vouloir modifier <b>{{name}}</b> de Sites multiples à Site unique'
};

export default organization;
