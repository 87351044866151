import { useSelector } from 'store';
import { hasLockdownStarted } from 'helpers/hasLockdownStarted';

export const useSelectedSiteLockdownStatus = () => {
  const selectedSiteId = useSelector((state) => state.selectedSite.site?.site);
  const lockdowns = useSelector((state) => state.lockdown.lockdown);

  const hasStarted = hasLockdownStarted(selectedSiteId, lockdowns);

  return { isLockdownActive: hasStarted };
};
