const organization = {
  organizations: 'Cuentas',
  search_organization: 'Buscar Cuentas',
  create_organization: 'Crear Cuentas',
  add_organization: 'Añadir cuenta',
  add_new_organization: 'Agregar nueva cuenta',
  organization_role: 'Rol de la cuenta',
  controllers: 'Controladores',
  search_controller: 'Controladores de búsqueda',
  organization: 'Cuenta',
  monthly: 'Mensual',
  yearly: 'Anual',
  doors: 'Puertas',
  doors_licenses: 'Licencias de puertas',
  pricing: 'Precios',
  profile: 'Perfil',
  billing: 'Facturación',
  search_billing: 'Buscar Facturación',
  licenses: 'Licencias',
  subscription: 'Suscripción',
  users: 'Usuarios',
  fee: 'Tarifa',
  amount: 'cantidad',
  how_many_doors_msg: '¿Cuántas puertas quieres activar?',
  payment_methods: 'Métodos de pago',
  name_on_card: 'Nombre en la tarjeta',
  card_number: 'Número de tarjeta',
  expiration_date: 'Fecha de caducidad',
  is_default: 'Hacer como predeterminado',
  expires_at: 'La tarjeta caduca a las',
  confirm_checkout_msg: '¿Está seguro de que desea pagar?',
  skip_checkout_msg:
    'Puedes comprar la puerta más tarde en tu cuenta. ¿Quieres continuar?',
  request_processed: 'Tu solicitud ha sido procesada',
  set_organization: 'Establecer cuenta',
  change_organization_msg: '¿Quieres cambiar la cuenta?',
  select_organization_msg: 'Por favor vaya a cuentas y seleccione una',
  summary: 'Resumen',
  invoices: 'Facturas',
  invoice: 'Factura',
  paid: 'Pagado',
  pending: 'Pendiente',
  mobile_passes: 'Pases Móviles',
  next_invoice: 'Siguiente Factura',
  last_update: 'Última actualización',
  set_default: 'Establecer Predeterminado',
  organization_created_msg: '¡Se ha creado {{account}}!',
  activate_controller_msg:
    'No hay controladores añadidos todavía. Inicie su acceso agregando su primer controlador',
  single_site: 'Sitio único',
  validate: {
    type_email: 'Por favor, escriba su correo electrónico',
    verification_code: 'Código de verificación',
    validation_label:
      'A validation code has been sent to your email, please check your inbox',
    done: 'Hecho',
    success: '¡Éxito! Ya puedes usar tu cuenta CDVI Cloud',
    continue: 'Continuar',
    finish: 'Finalizar'
  },
  table: {
    company: 'COMPAÑÍA',
    full_name: 'NOMBRE COMPLETO',
    email: 'CORREO ELECTRÓNICO',
    country: 'PAÍS',
    last_login: 'ÚLTIMO ACCESO',
    active: 'ACTIVO',
    address: 'DIRECCIÓN',
    status: 'ESTADO',
    site: 'SITIO',
    serial_number: 'NÚMERO DE SERIE',
    ip_address: 'DIRECCIÓN IP',
    port: 'PUERTO',
    organization: 'CUENTA',
    firmware_version: 'VERSIÓN DE FIRMWARE',
    date: 'FECHA',
    invoice: 'FACTURA',
    amount: 'CANTIDAD',
    payment: 'PAGO',
    invoice_number: 'NÚMERO FACTURA',
    type: 'TIPO',
    total: 'TOTAL'
  },
  form: {
    type: 'Tipo de distribuidor',
    company: 'Compañía',
    full_name: 'Nombre completo',
    first_name: 'Nombre de pila',
    main_contact_name: 'Contacto principal - Nombre',
    last_name: 'Apellido',
    second_contact_name: 'Segundo contacto - Nombre',
    second_contact_last_name: 'Apellido',
    email: 'Correo electrónico',
    country: 'País',
    address: 'DIRECCIÓN',
    city: 'Ciudad',
    state: 'Estado',
    zip_code: 'Código postal',
    phone: 'Teléfono',
    mobile: 'Móvil',
    create_admin: 'Crear un usuario',
    timezone: 'Zona horaria',
    username: 'Nombre de usuario'
  },
  information: {
    organization: 'Cuenta',
    dealer: 'Distribuidor',
    company: 'Compañía',
    first_name: 'Nombre de pila',
    last_name: 'Apellido',
    email: 'Correo electrónico',
    username: 'Nombre de usuario',
    website: 'Sitio web',
    country: 'País',
    address: 'DIRECCIÓN',
    city: 'Ciudad',
    state: 'Estado',
    zipcode: 'Código postal',
    timezone: 'Zona horaria',
    region: 'Región',
    phone: 'Teléfono'
  },
  modal: {
    title: 'Eliminar Suscripción',
    remove_title: 'Remove Subscription',
    description: 'Cuántas puertas le gustaría agregar?',
    current_subscription: 'Suscripción Actual',
    previous: 'Anterior',
    checkout: 'Checkout',
    change_card: 'Cambiar Tarjeta',
    invoice_details: 'Detalles de Factura',
    current: 'Actual',
    next_cycle: 'Siguiente Ciclo',
    request_processed: 'Tu solicitud ha sido procesada',
    print_receipt: 'Imprimir Recibo',
    download_receipt: 'Descargar Recibo',
    finish: 'Finalizar',
    due_today: 'Para Hoy',
    confirmation_description:
      'El pago no se puede modificar después de marcar esta ventana, ¿estás seguro de que quieres pagar?',
    total_amount: 'Monto Total',
    remove_confirmation_description:
      'Al pulsar el botón de \'confirmar\', el proceso de eliminación de puertas o pases móviles se realizará de forma inmediata.',
    remove_description: 'Cuántas puertas te gustaría quitar?',
    remove: 'Eliminar',
    confirmation: 'Confirmación',
    delete_payment_description: 'Está seguro de eliminar esta tarjeta',
    default_payment_description:
      'Está seguro de que quieres configurar esta tarjeta como predeterminada'
  },
  error: {
    invalid_email: 'Formato de correo inválido'
  },
  set_multisite_msg:
    '¿Está seguro de que desea cambiar <b>{{name}}</b> de sitio único a sitios múltiples?',
  set_singlesite_msg:
    '¿Está seguro de que desea cambiar <b>{{name}}</b> de sitios múltiples a sitio único?'
};

export default organization;
